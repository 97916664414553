import { PhoneIcon } from "@heroicons/react/20/solid";
import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../assets/linkedin.svg";
import { ReactComponent as EmailIcon } from "../assets/mail.svg";
import { ReactComponent as WhatsappIcon } from "../assets/whatsapp.svg";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-white flex flex-col items-center justify-center p-6">
      <hr className="mt-10 mb-10 border-t border-zinc-100 w-full"></hr>
      {/* logo and title */}
      <div className="mb-10 lg:mb-16 lg:ml-20 flex items-center justify-start w-full">
        <img
          src="/images/MES-Logo.png"
          alt="MES-logo"
          className="h-auto max-h-28 w-auto max-w-[50vw] text-slate-900 -mr-5"
          style={{ minHeight: "16px", minWidth: "16px" }}
        />
        <h3 className="ml-5 text-2xl font-bold text-sky-700 md:text-3xl lg:text-4xl">
          Medical Emergency Solutions
        </h3>
      </div>
      {/* address and icons */}
      <div className="flex flex-col lg:px-18 md:flex-row justify-between w-full">
        {/* address + buttons */}
        <div className="flex flex-col md:flex-row md:items-end md:w-1/2">
          <div className=" ml-5 md:ml-10 mb-6 md:mb-0 lg:mr-10">
            <h3 className="text-lg md:text-xl font-semibold text-sky-700">
              Head Office
            </h3>
            <p className="text-md text-gray-600">Unit 9,</p>
            <p className="text-md text-gray-600">199 Camford Way,</p>
            <p className="text-md text-gray-600">Luton LU3 3AN</p>
            <p className=" text-md leading-8 text-gray-600 flex flex-row items-center">
              <PhoneIcon aria-hidden="true" className="h-3 w-3  mr-1" />
              01582 292826
            </p>
          </div>

          <div className="my-5 md:mt-0 flex md:flex-col flex-row gap-x-6 gap-y-4 md:items-center w-full justify-center md:w-1/2">
            <a
              href="mailto:info@medicalemergencysolutions.co.uk"
              className="flex items-center rounded-md bg-sky-600 px-3.5 py-2.5 text-sm md:text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              <EmailIcon aria-hidden="true" className="h-6 w-6 mr-2" />
              Email us
            </a>
            <a
              href="tel:+441582292826"
              className="flex items-center text-sm md:text-md font-semibold text-sky-600 hover:text-teal-600"
            >
              <PhoneIcon aria-hidden="true" className="h-6 w-6  mr-2" />
              Call us
              <span aria-hidden="true" className="ml-1">
                →
              </span>
            </a>
          </div>
        </div>
        {/* icons */}
        <div className="flex flex-col items-center md:items-end md:w-1/2 mt-10 md:h-full md:mt-0 lg:mr-20 ">
          <div className="flex gap-x-10 mb-6 md:h-full md:justify-items-end">
            <a
              href="https://www.facebook.com/profile.php?id=61563613482665"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to MES Facebook Page"
            >
              <FacebookIcon
                aria-hidden="true"
                className="scale-100 md:scale-130"
              />
            </a>
            <a
              href="https://www.instagram.com/medicalemergencysolutions?igsh=MWpub3hrNXdncjB1bA=="
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to MES Instagram Profile"
            >
              <InstagramIcon
                aria-hidden="true"
                className="scale-100 md:scale-130"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/medical-event-solutions/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Go to MES LinkedIn Page"
            >
              <LinkedinIcon
                aria-hidden="true"
                className="scale-100 md:scale-130"
              />
            </a>
            <a
              href="https://wa.me/441582292826"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Contact MES on WhatsApp"
            >
              <WhatsappIcon
                aria-hidden="true"
                className="scale-100 md:scale-130"
              />
            </a>
          </div>
          {/* <div className="flex flex-wrap items-end md:justify-start space-y-5 md:space-y-0 space-x-10 justify-items-center md:items-end">
            <img
              src="/icons/Group-1864.png"
              alt="CQC logo"
              className="h-10 w-20 md:h-10 md:w-20 group-hover:text-indigo-600"
            />
            <img
              src="/icons/Information_Commissioners_Office_logo.svg.png"
              alt="ICO logo"
              className="h-10 w-16 md:h-10 md:w-18 group-hover:text-indigo-600"
            />
            <img
              src="/icons/logo-fq-white@2x.png"
              alt="FQ logo"
              className="h-8 w-30 md:h-8 md:w-32 group-hover:text-indigo-600"
            />
          </div> */}
        </div>
      </div>
      <hr className="mt-10 mb-10 border-t border-zinc-100 w-full"></hr>
      {/* policies */}
      <div className="flex flex-col md:flex-row items-center md:justify-between space-y-4 md:space-y-0 md:space-x-10">
        <div className="md:flex-1 text-center text-sm">
          <p>2023 - 2024 Medical Emergency Solutions Limited™ </p>
        </div>
        {/* links to policies */}
        <div className="flex flex-row space-x-6">
          <Link to="/privacy">
            <p className="hover:underline text-sm">Privacy Policy</p>
          </Link>
          <Link to="/terms-and-conditions">
            <p className="hover:underline text-sm">Terms & Conditions</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";

export default function CareersHero() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      <picture>
        <source
          srcSet="/images/mes-careers-hero-768.png"
          media="(max-width: 500px)"
        />
        <source
          srcSet="/images/mes-careers-hero-900.png"
          media="(max-width: 768px)"
        />
        <source
          srcSet="/images/mes-careers-hero-1200.png"
          media="(max-width: 1000px)"
        />
        <source srcSet="/images/mes-careers-hero-1400.png" />

        <img
          src="/images/mes-careers-hero-1400.png"
          alt="Paramedic checking equipment and supplies"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right-top "
        />
      </picture>
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#082f49] to-[#0284c7] opacity-20"
          style={{
            clipPath:
              "clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#082f49] to-[#0284c7] opacity-20"
          style={{
            clipPath:
              "clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div className="ml-0 max-w-2xl mr-auto md:ml-10 px-6 lg:px-8">
        <div className="justify-start mb-8">
          <Link to="/">
            <span className="relative text-lg leading-6 text-gray-300 underline underline-offset-4 ">
              &larr; Return to Home
            </span>
          </Link>
        </div>
        <div className="text-start">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Empowering Our Team for Excellence
          </h1>
          <p className="mt-6 text-xl leading-8 text-white">
            Our staff is at the heart of delivering outstanding patient care. We
            foster a <strong>welcoming and supportive environment</strong>,
            crucial for recruiting and developing dedicated professionals.
          </p>
          <p className="mt-6 text-xl leading-8 text-white">
            By investing in thorough training and ongoing support, we ensure our
            team provides <strong>compassionate and respectful care</strong>{" "}
            across both NHS and private sectors.
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10"></div>
        </div>
        <div className="mt-6 flex items-center justify-start gap-x-6">
          <Link
            to="/about"
            className="rounded-md bg-sky-600 px-3.5 py-2.5 text-sm md:text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            About MES
          </Link>
          <Link
            to="/contacts"
            className="text-sm md:text-md font-semibold leading-6 text-white hover:text-teal-600"
          >
            Contact us <span aria-hidden="true">→</span>
          </Link>
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";

export default function WorkWithUs() {
  return (
    <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
      {/* <img
        src="/images/team.jpg"
        alt="Ambulance staff posing with ambulance in the background"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center md:object-top"
      /> */}

      <picture>
        {/* <source
          srcSet="/images/mes-joinus-480.png"
          media="(max-width: 768px)"
        /> */}
        <source
          srcSet="/images/mes-joinus-768.png"
          media="(max-width: 1200px)"
        />

        <source srcSet="/images/mes-joinus-1200.png" />

        <img
          alt="Ambulance staff posing with ambulance in the background"
          src="/images/mes-joinus-1200.png"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-center md:object-top "
          loading="lazy"
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 50vw"
        />
      </picture>
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#082f49] to-[#0284c7] opacity-20"
          style={{
            clipPath:
              "clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#082f49] to-[#0284c7] opacity-20"
          style={{
            clipPath:
              "clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        ></div>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Work with us
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-100">
            Join a dynamic team of{" "}
            <strong>over 50 dedicated bank professionals</strong>, where growth
            and collaboration are at the heart of what we do. We prioritise the
            well-being of our staff, offering{" "}
            <strong>continuous support</strong> throughout their entire journey
            with us.
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-100">
            Discover exciting career opportunities with us and explore how you
            can join our dynamic team.
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
          <div className="mt-10 flex items-center justify-start gap-x-6">
            <Link
              to="/about"
              className="rounded-md bg-sky-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              About MES
            </Link>
            <Link
              to="/careers"
              className="text-md font-semibold leading-6 text-white hover:text-teal-600"
            >
              Career opportunities <span aria-hidden="true">→</span>
            </Link>
          </div>
          {/* <dl className="mt-16 grid grid-cols-1 gap-8 sm:mt-20 sm:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-300">
                Work Locations
              </dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                2
              </dd>
            </div>
            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-300">
                Bank collaborators
              </dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                100+
              </dd>
            </div>

            <div className="flex flex-col-reverse">
              <dt className="text-base leading-7 text-gray-300">
                Paid time off
              </dt>
              <dd className="text-2xl font-bold leading-9 tracking-tight text-white">
                Unlimited
              </dd>
            </div>
          </dl> */}
        </div>
      </div>
    </div>
  );
}

import { Link } from "react-router-dom";

export default function Carbon() {
  return (
    <div className="overflow-hidden bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {/* <img
            alt="zero emission sustainability logo"
            src="/images/zerocarbon.png"
            className="w-full sm:w-3/4 lg:w-full max-w-none object-cover object-center mx-auto"
                  /> */}
          <picture>
            <source
              srcset="/images/zerocarbon_540px.png"
              media="(max-width: 768px)"
            />

            <source srcset="/images/zerocarbon_840px.png" />
            <img
              alt="zero emission sustainability logo"
              src="/images/zerocarbon_840.png"
              className="w-3/4 max-w-none object-cover object-center mx-auto"
              loading="lazy"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 50vw"
            />
          </picture>
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="test-md text-base font-semibold leading-7 text-sky-700">
                Sustainability
              </h2>

              <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Working Towards a Greener Future
              </h3>

              <p className="mt-6 text-lg leading-8 text-gray-600">
                Medical Emergency Solutions is committed to achieving{" "}
                <strong>Net Zero emissions by 2045.</strong> From adopting
                electric vehicles to increasing energy efficiency, we’re taking
                meaningful steps to reduce our carbon footprint.
              </p>

              <div className="mt-10 flex items-start gap-x-6 ">
                <Link
                  to="/sustainability"
                  className="text-md md:text-md font-semibold leading-6 text-sky-700 hover:text-teal-600"
                >
                  Learn More About Our Sustainability Plan
                  <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Mission() {
  return (
    <div className="overflow-hidden bg-white mb-28 py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-sky-700">
                Our focus
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Delivering the highest standards of care to patients in both the
                NHS and private sector
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Our operations are meticulously designed to provide the best
                <strong> customised service</strong> to meet each client's
                unique needs. Our dedicated teams are equipped with the latest
                technology and training, allowing us to offer flexible solutions
                that adapt to any scenario.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                This commitment to excellence extends to our{" "}
                <strong>leadership</strong>. Our directors maintain a hands-on
                approach, staying fully informed about all ongoing operations to
                ensure consistent adherence to high standards. This proactive
                engagement fosters{" "}
                <strong>trust and confidence among our clients</strong>, who
                benefit from our responsive support and guidance.
              </p>
            </div>
          </div>{" "}
          {/* <img
            alt="MES private ambulance transport service to an airport"
            src="/images/mes_transfer-2.jpg"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
          /> */}
          <picture>
            {/* <source
              srcset="/images/mes-airport-ambulance-transfer-480.png"
              media="(max-width: 768px)"
            /> */}
            <source
              srcSet="/images/mes-airport-ambulance-transfer-768.png"
              media="(max-width: 1200px)"
            />

            <source srcSet="/images/mes-airport-ambulance-transfer-1200.png" />

            <img
              alt="MES private ambulance transport service to an airport"
              src="/images/mes-airport-ambulance-transfer-1200.png"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              loading="lazy"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 50vw"
            />
          </picture>
        </div>
      </div>
    </div>
  );
}

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Sustainability() {
  return (
    <>
      <Helmet>
        <title>MES Website - Sustainability</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/sustainability"
        />
        <meta
          name="description"
          content="MES is committed to achieving Net Zero emissions by 2045. From adopting electric vehicles to increasing energy efficiency, we’re taking meaningful steps to reduce our carbon footprint."
        />
      </Helmet>
      <div className="overflow-hidden bg-white py-20 px-6 sm:px-12 max-w-7xl mx-auto">
        <div className="justify-start mb-8">
          <Link to="/about">
            <span className="relative text-lg leading-6 text-gray-600 underline underline-offset-4 ">
              &larr; Return to About MES
            </span>
          </Link>
        </div>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Our Commitment to Sustainability
        </h1>
        <div className="mt-8 space-y-6">
          <div>
            <p className="mt-2 text-lg leading-8 text-gray-600">
              At Medical Emergency Solutions, we are deeply committed to
              reducing our environmental impact and achieving Net Zero emissions
              by 2045. Sustainability is at the heart of how we operate, and our
              Carbon Reduction Plan sets out a clear roadmap for achieving our
              goals.
            </p>
          </div>

          <div className="mt-2 space-y-5 text-gray-600">
            <h2 className="text-xl font-semibold text-sky-700">
              Carbon Reduction Plan Highlights
            </h2>
            <div>
              <h3 className="text-lg font-semibold text-sky-700">
                Baseline Year: 2024
              </h3>
              <ul className="list-disc list-inside text-lg leading-8 text-gray-600">
                <li>Total Emissions: 277.9 tCO2e</li>
                <li>Scope 1: 42.6 tCO2e</li>
                <li>Scope 2: 14.9 tCO2e</li>
                <li>
                  Scope 3: 220.4 tCO2e (including purchased goods and services,
                  business travel, and employee commuting)
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-sky-700">
                Reduction Targets:
              </h3>

              <p className="text-lg text-grey-600">
                We aim to reduce our carbon emissions by 20% by 2029, targeting
                a total of 232 tCO2e within five years.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-sky-700">
                Completed Initiatives:
              </h3>
              <p className="text-lg text-grey-600">
                We’ve already made strides with the following measures:
              </p>
              <ul className="list-disc list-inside text-lg leading-8 text-gray-600">
                <li>Integration of electric and hybrid vehicles</li>
                <li>Promoting cycling and walking for staff</li>
                <li>Enhanced recycling practices</li>
                <li>Increased awareness of energy efficiency in offices</li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-sky-700">
                Future Projects:
              </h3>
              <p className="text-lg text-grey-600">
                Looking ahead, we plan to:
              </p>
              <ul className=" list-disc list-inside text-lg leading-8 text-gray-600">
                <li>Utilize solar power for buildings</li>
                <li>Transition to fully electric or hybrid vehicle fleets</li>
                <li>Shift to renewable electric energy sources</li>
              </ul>
              <p className="text-lg text-grey-600">
                This plan underscores our dedication to environmental
                stewardship and our commitment to continuous improvement.
              </p>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-xl font-semibold text-sky-700 mt-5">
            Emissions Reporting
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            All emissions data has been calculated and verified according to the
            GHG Reporting Protocol and associated government standards. For full
            details, you can read or download our complete Carbon Reduction Plan
            2024.
          </p>
          <a
            href="/files/CarbonReductionPlan2024.pdf"
            download
            className="inline-block mt-4 px-6 py-2 text-white bg-sky-600 hover:bg-teal-600 font-medium rounded-md text-lg"
          >
            Download Carbon Reduction Plan (PDF)
          </a>
        </div>
      </div>
    </>
  );
}

import { Link } from "react-router-dom";

export default function PreHospitalHero() {
  return (
    <div className="relative isolate py-32 sm:py-48 lg:py-56 px-6 pt-18 lg:px-8">
      <picture>
        <source
          srcSet="/images/mes-luton-ambulances-768.png"
          media="(max-width: 500px)"
        />
        <source
          srcSet="/images/mes-luton-ambulances-900.png"
          media="(max-width: 768px)"
        />
        <source
          srcSet="/images/mes-luton-ambulances-1200.png"
          media="(max-width: 1000px)"
        />
        <source srcSet="/images/mes-luton-ambulances-1400.png" />

        <img
          src="/images/mes-luton-ambulances-1400.png"
          alt="Medical emergency solutions ambulance vehicles lined up at the company's base in Luton"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-center "
        />
      </picture>

      <div className="ml-0 h-full mr-auto max-w-2xl md:ml-10 flex flex-col">
        <div className="justify-start mb-8">
          <Link to="/">
            <span className="relative text-lg leading-6 text-gray-300 underline underline-offset-4 ">
              &larr; Return to Home
            </span>
          </Link>
        </div>
        <div className="text-start">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Pre-Hospital Care
          </h1>
          <p className="mt-6 text-xl leading-8 text-white ">
            With years of experience on the{" "}
            <a
              href="https://www.england.nhs.uk/"
              target="blank"
              rel="noopener noreferrer"
              className="font-bold text-white hover:text-teal-600"
            >
              NHS frontline
            </a>
            , our team is highly skilled in providing emergency care, ensuring
            the health and safety of our patients.
          </p>
          <p className="mt-6 text-xl leading-8 text-white ">
            MES ambulance crew have extensive experience in handling a wide
            range of critical situations, ensuring that patients receive
            <strong> immediate and expert care</strong> from the moment they
            arrive at the scene.
          </p>
          <div className="mt-10 flex items-center justify-start gap-x-6">
            {/* <Link
              to=""
              className="rounded-md bg-sky-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Transfers
            </Link> */}
            <Link
              to="/contacts"
              className="text-md font-semibold leading-6 text-white hover:text-teal-600"
            >
              Contact us <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Technology() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <span className="text-base font-semibold leading-7 text-sky-700">
                Proud of our
              </span>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Commitment to Cutting-edge Technology
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Medical Emergency Solutions leverages the{" "}
                <strong>latest advancements</strong> in medical equipment and
                communication systems to enhance their response capabilities.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                This includes state-of-the-art medical equipment, communication
                devices, and digital record-keeping systems, all of which
                contribute to the <strong>efficiency and effectiveness</strong>{" "}
                of their medical services.
              </p>
            </div>
          </div>{" "}
          <picture>
            <source
              srcSet="/images/mes-equipment-768.png"
              media="(max-width: 490px)"
            />

            <source srcSet="/images/mes-equipment-900.png" />

            <img
              src="/images/mes-equipment-900.png"
              alt="Medical equipment and monitors inside ambulance"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
          </picture>
        </div>
      </div>
    </div>
  );
}

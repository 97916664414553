import Highlights from "../../components/highlights";
import AboutServices from "./AboutServices";

import Directors from "./Directors";
import People from "../../components/People";
import AboutUsHero from "./AboutUsHero";
import Clients from "../../components/Clients";
import SeparatorRight from "../../components/SeparatorRight";
import SeparatorLeft from "../../components/SeparatorLeft";
import Location from "../../components/Location";
import Compliance from "../../components/Compliance";
import { Helmet } from "react-helmet";
import Carbon from "../../components/Carbon";

export default function AboutUs() {
  return (
    <>
      <Helmet>
        <title>Meet the Team Behind Our Private Ambulance Company</title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/about"
        />
        <meta
          name="description"
          content="MES is a pioneering consultant-lead company delivering medical management services, including Acute Medical Service Provisions and Event Medical Support."
        />
      </Helmet>
      <AboutUsHero />
      <SeparatorRight />
      <Directors />
      <People />
      <Carbon />
      <Compliance />
      <Clients />
      <AboutServices />
      <SeparatorLeft />
      <Highlights />
      <Location />
    </>
  );
}

import React from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

export default function CookieConsent() {
  const [, setCookie] = useCookies(["cookieConsent"]);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg p-4 z-50">
      <div className="container mx-auto flex flex-col sm:flex-row items-center justify-between">
        <p className="text-gray-700 text-sm ">
          We use cookies to enhance your user experience. By using our website,
          you agree to our use of cookies. For more details, read our{" "}
          <Link to="/privacy" className="text-sky-600 underline">
            Cookie Policy.
          </Link>
        </p>
        <button
          className="bg-sky-700 text-white py-2 px-4 rounded hover:bg-sky-800 m-2"
          onClick={giveCookieConsent}
        >
          Accept
        </button>
      </div>
    </div>
  );
}

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const features = [
  {
    name: "GMC Registered Doctors",

    link: "https://www.gmc-uk.org/",
  },
  {
    name: "HCPC Paramedics",

    link: "https://www.hcpc-uk.org/",
  },
  {
    name: "NMC Nurses",

    link: "https://www.nmc.org.uk/",
  },

  {
    name: "HPAC IHCD Ambulance Technicians",

    link: "https://www.hpac-uk.org/",
  },
];

export default function Staff() {
  return (
    <div className="overflow-hidden bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <picture>
            <source
              srcSet="/images/mes-staff-overseas-transfer-500.png"
              media="(max-width: 490px)"
            />

            <source srcSet="/images/mes-staff-overseas-transfer-768.png" />

            <img
              src="/images/mes-staff-overseas-transfer-768.png"
              alt="three members of MES staff posing in front on ambulance"
              className="w-full  max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover object-center self-center"
            />
          </picture>

          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <span className="text-base font-semibold leading-7 text-sky-700">
                With Honesty and Integrity
              </span>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Performing at the Highest Standards
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                MES staff are trained and supported to foster professional
                development, maximising their skills and education to protect
                patient safety and dignity. This will guarantee that all
                personnel will be able to accomplish their role and
                responsibilities to the highest standard.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We ensure our team members adhere to our policies, procedures,
                and regulations, and maintain compliance with the respective
                regulatory bodies.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Furthermore, our employees and vehicles are fully insured for
                public liability and medical malpractice, and all staff undergo
                DBS checks. This comprehensive approach guarantees a safe and
                reliable service for all our patients.
              </p>
              <dl className="mt-10 max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <ChevronRightIcon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-sky-700"
                      />
                      <a
                        href={feature.link}
                        target="blank"
                        rel="noopener noreferrer"
                        className="hover:text-teal-600"
                      >
                        {feature.name}
                      </a>
                    </dt>{" "}
                  </div>
                ))}
              </dl>
              <div className="mt-10 flex items-start gap-x-6 ml-10">
                <Link
                  to="/careers"
                  className="text-md font-semibold leading-6 text-sky-800 hover:text-teal-600"
                >
                  Join us <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

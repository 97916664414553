import { Link } from "react-router-dom";

export default function EventStatement() {
  return (
    <div className="overflow-hidden bg-white mb-28 py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <span className="text-base font-semibold leading-7 text-sky-700">
                Pre-event planning
              </span>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Collaborating closely with event organisers
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Ensuring event first aid coverage means{" "}
                <strong>
                  attendees are well cared for in case of a medical emergency
                </strong>
                . Whether dealing with a minor injury or a critical situation,
                having event medical cover provides peace of mind for both
                organisers and attendees.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                MES conducts thorough <strong>risk assessments</strong>, taking
                into account factors such as crowd size, event nature, and
                potential health risks . This enables us to develop{" "}
                <strong>comprehensive medical plans</strong>, ensuring we are
                well-prepared to handle any medical emergencies that may arise.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                During events, MES deploys a team of{" "}
                <strong>highly trained and experienced</strong> medical
                professionals. From paramedics to physicians, our staff are
                equipped to handle a diverse range of medical situations,{" "}
                <strong>providing immediate care and support</strong> when
                needed. Medical facilities and equipment are strategically
                positioned throughout the event venue, optimising response times
                and overall efficacy.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                To learn more about how MES can support your event or to discuss
                your specific medical coverage needs,{" "}
                <Link
                  to="/contacts"
                  className="font-bold text-sky-800 hover:text-teal-600"
                >
                  contact us
                </Link>{" "}
                today.
              </p>
            </div>
          </div>{" "}
          <picture>
            <source
              srcSet="/images/mes-events-music-festival-500.png"
              media="(max-width: 490px)"
            />

            <source srcSet="/images/mes-events-music-festival-768.png" />

            <img
              src="/images/mes-events-music-festival-768.png"
              alt="Crowd of music lovers at a festival"
              className="w-full rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] mx-auto"
            />
          </picture>
        </div>
      </div>
    </div>
  );
}

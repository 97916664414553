import { Link } from "react-router-dom";

export default function Location() {
  return (
    <div className="overflow-hidden bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <picture>
            <source
              srcSet="/images/mes-base-550.jpg"
              media="(max-width: 490px)"
            />
            <source
              srcSet="/images/mes-base-768.jpg"
              media="(max-width: 900px)"
            />

            <source srcSet="/images/mes-base-900.jpg" />

            <img
              src="/images/mes-base-900.jpg"
              alt="Medical base and monitors inside ambulance"
              className="w-full md:w-3/4  max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover object-center mx-auto"
            />
          </picture>

          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="test-md text-base font-semibold leading-7 text-sky-700">
                We are located in
              </h2>

              <h3 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Luton & Central/West London
              </h3>

              <p className="mt-6 text-lg leading-8 text-gray-600">
                We take pride in offering services across the UK, with our
                headquarters located in Luton. To ensure prompt response times,
                we also have ambulances stationed in London.
              </p>

              <div className="mt-10 flex items-start gap-x-6 ">
                <Link
                  to="/contacts"
                  className="text-md md:text-md font-semibold leading-6 text-sky-700 hover:text-teal-600"
                >
                  Find us<span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

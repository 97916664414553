// We undertake repatriation of patients in the UK and Europe for insurance and self pay clients
// Our team travel as far as South of Spain, Poland and Greece
// We are always up to date with all border control restrictions and each country’s COVID policies
// Work closely with insurance companies to ensure safe medical escort of patients who have faced unforeseen situations or become ill abroad

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const features = [
  {
    name: "Long Distance Ambulance Transfer.",
    description:
      "Our services have extended to destinations as far as the south of Spain, Poland, and Greece.",

    icon: ChevronRightIcon,
  },
  {
    name: "Travel Regulations.",
    description:
      " We stay current with all border control restrictions and each country's COVID policies to ensure smooth operations.",

    icon: ChevronRightIcon,
  },
  {
    name: "Collaboration with Insurance Companies.",
    description:
      "Collaborating closely with insurance companies, we provide secure medical escorts for patients who encounter unforeseen situations or illnesses while abroad.",
    icon: ChevronRightIcon,
  },
  {
    name: "Advanced Comfort Solutions.",
    description:
      "We utilise state-of-the-art vacuum mattresses to ensure patients remain comfortable during long-distance transport.",

    icon: ChevronRightIcon,
  },
];

export default function Repatriation() {
  return (
    <div className="overflow-hidden bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <picture>
            <source
              srcSet="/images/mes-overseas-transfer-500.png"
              media="(max-width: 490px)"
            />

            <source srcSet="/images/mes-overseas-transfer-768.png" />

            <img
              src="/images/mes-overseas-transfer-768.png"
              alt="MES ambulance inside LeShuttle train"
              className="w-full  max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover object-center self-center"
            />
          </picture>

          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <span className="text-base font-semibold leading-7 text-sky-700">
                Overseas Patients
              </span>

              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Secure Repatriation and Medical Escort Services
              </h2>

              <h3 className="mt-6 text-lg leading-8 text-gray-600">
                We specialise in the repatriation of patients across the UK and
                Europe, catering to both insurance and self-pay clients.
              </h3>

              <dl className="mt-10 max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-sky-700"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10 flex items-start gap-x-6 ml-10">
                <Link
                  to="/contacts"
                  className="text-md font-semibold leading-6 text-sky-800 hover:text-teal-600"
                >
                  Contact us <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

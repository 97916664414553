export default function EventTypes() {
  const events = [
    {
      id: 1,
      title: "Boxing",
      description:
        "MES is the number one choice for the Boxing League in the UK and international fights.",
      image: "/images/mes-events-boxing-768.png",
      imageAlt: "Fighters and referee in a boxing ring",
    },
    {
      id: 2,
      title: "Sports events",
      description:
        "Football, marathons, triathlons, rugby... Our expert team is prepared to offer first-class support to any sport event.",
      image: "/images/mes-events-running-768.png",
      imageAlt: "Runner crossing final line at a marathon",
    },
    {
      id: 3,
      title: "Motorsports",
      description:
        "We provide exceptional medical support for motorsports events, ensuring rapid response and comprehensive care for drivers and spectators alike.",
      image: "/images/mes-events-motocross-700.png",
      imageAlt: "Two motorbikes racing",
    },
    {
      id: 4,
      title: "Conferences",
      description:
        "Our dedicated medical team offers reliable and discreet support for business conferences, ensuring the health and safety of all attendees.",
      image: "/images/mes-events-conference-700.png",
      imageAlt: "Speaker at a conference and their audience",
    },
    {
      id: 5,
      title: "TV and Film",
      description:
        "MES provides expert medical support for TV and film productions, ensuring on-set safety and prompt care for cast and crew.",
      image: "/images/mes-events-film-700.png",
      imageAlt: "Cameraman and two other members of film production",
    },
    {
      id: 6,
      title: "Concerts and Festivals",
      description:
        "MES offers comprehensive medical coverage for concerts and festivals, ensuring prompt and effective care for attendees in vibrant and high-energy environments.",
      image: "/images/mes-events-concert-700.png",
      imageAlt:
        "Smoke projected from the stage at a music concert with an audience",
    },
    {
      id: 7,
      title: "Private Events",
      description:
        "We deliver top-tier medical support for private events, offering personalised care and ensuring the well-being of your guests.",
      image: "/images/mes-events-private-event-700.png",
      imageAlt: "Aerial view of a wedding venue",
    },
    {
      id: 8,
      title: "Sports days",
      description:
        "MES provides attentive and compassionate medical support for sports days, ensuring a safe and enjoyable experience for young athletes.",
      image: "/images/mes-events-school-700.png",
      imageAlt: "Group of children playing with balls",
    },
  ];
  return (
    <div id="event-list" className="bg-sky-800 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto flex flex-col justify-center lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center">
            Experienced First Aid Event Coverage
          </h2>
          <h3 className="mt-2 text-xl leading-8 text-gray-300 text-center">
            Medical Emergency Solutions has extensive experience providing
            coverage for the following types of events:
          </h3>
        </div>
        <div className="mx-auto mt-10 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none">
          <div className="grid grid-cols-1 gap-y-4 gap-x-8">
            {events.map((event) => (
              <article
                key={event.id}
                className="grid grid-cols-1 w-full min-h-100 md:grid-cols-2 bg-white rounded-lg shadow-lg"
              >
                <div
                  className={`w-full h-60 ${
                    event.id % 2 === 0 ? "md:order-last" : "md:order-first"
                  }`}
                >
                  <img
                    className="object-cover w-full h-full 
              rounded-t-lg 
              "
                    src={event.image}
                    alt={event.imageAlt}
                  />
                </div>
                <div
                  className={`p-4 rounded-lg flex justify-center items-center bg-white ${
                    event.id % 2 === 0 ? "md:order-first" : "md:order-last"
                  }`}
                >
                  <div className="group relative">
                    <h4 className="mt-3 text-xl font-semibold leading-6 text-sky-800 group-hover:text-gray-600 text-center">
                      <span className="absolute inset-0" />
                      {event.title}
                    </h4>
                    <p className="mt-5  text-md leading-6 text-gray-600 text-center pb-2">
                      {event.description}
                    </p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

// grid grid-cols-1 md:grid-cols-2 items-center justify-between p-6 bg-white rounded-lg shadow-lg

export default function Compliance() {
  const icons = [
    { img: "/icons/mes-cqc.png", alt: "Care Quality Commission Logo" },
    {
      img: "/icons/mes-nhs-datasecurity.png",
      alt: "NHS Data Security and Protection Toolkit",
    },
    {
      img: "/icons/mes-ico-blue.png",
      alt: "Information Commissioner's Office",
    },
    {
      img: "/icons/mes-cyber-essentials.png",
      alt: "Cyber Essentials Certification",
    },
    {
      img: "/icons/mes-iso-90012015.png",
      alt: "ISO 9001-2015 Certification",
    },
    {
      img: "/icons/mes-iso-270012013.png",
      alt: "ISO 27001-2013 Certification",
    },
  ];
  return (
    <div className="overflow-hidden bg-white mb-28 py-10 sm:py-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <span className="text-base font-semibold leading-7 text-sky-700">
                We Strive for Clinical Excellence
              </span>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Commitment to Regulatory Compliance and Quality Care
              </h2>

              <p className="mt-6 text-lg leading-8 text-gray-600">
                As an independent and privately owned medical care and ambulance
                service, we are fully committed to adhering to the stringent
                standards set by various regulatory bodies. Our services are
                governed by the{" "}
                <a
                  href="https://www.legislation.gov.uk/ukpga/2008/14/contents"
                  target="blank"
                  rel="noopener noreferrer"
                  className="font-bold text-sky-700 hover:text-teal-600"
                >
                  Health and Social Care Act 2008
                </a>
                , and we operate under the oversight of the{" "}
                <a
                  href="https://www.cqc.org.uk/location/1-10598537375"
                  target="blank"
                  rel="noopener noreferrer"
                  className="font-bold text-sky-700 hover:text-teal-600"
                >
                  Care Quality Commission (CQC)
                </a>
                , along with other key regulatory authorities and
                certifications.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                We recognise and uphold our responsibilities as outlined by
                these regulations, continuously{" "}
                <strong>
                  striving to maintain compliance and deliver exceptional care{" "}
                </strong>
                in every aspect of our operations.
              </p>
            </div>
          </div>{" "}
          <div className="mx-auto mt-10 grid grid-cols-2 md:grid-cols-3 items-center gap-y-10 gap-x-10 lg:mx-0 ">
            {icons.map((icon) => (
              <img
                className="h-32 w-auto object-contain "
                src={icon.img}
                alt={icon.alt}
                width="158"
                height="100"
                key={icon.alt}
                loading="lazy"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

import { HashLink as Link } from "react-router-hash-link";

export default function EventHero() {
  return (
    <div className="relative isolate py-32 sm:py-48 lg:py-56 px-6 pt-18 lg:px-8 bg-gray-500">
      <picture>
        <source
          srcSet="/images/mes-events-hero-768.png"
          media="(max-width: 500px)"
        />
        <source
          srcSet="/images/mes-events-hero-900.png"
          media="(max-width: 768px)"
        />
        <source
          srcSet="/images/mes-events-hero-1200.png"
          media="(max-width: 1000px)"
        />
        <source srcSet="/images/mes-events-hero-1410.png" />

        <img
          src="/images/mes-events-hero-1410.png"
          alt="fighters in a boxing ring with audience"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-center "
        />
      </picture>

      <div className="ml-0 h-full mr-auto max-w-2xl md:ml-10 flex flex-col">
        <div className="justify-start mb-8">
          <Link to="/">
            <span className="relative text-lg leading-6 text-gray-300 underline underline-offset-4 ">
              &larr; Return to Home
            </span>
          </Link>
        </div>
        <div className="text-start">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
            Event Medical Cover
          </h1>
          <p className="mt-6 text-xl leading-8 text-white ">
            Whether it's a large-scale music festival, a sporting event, a
            corporate gathering, or any other type of public gathering, our
            company specialises in tailoring medical solutions to meet the
            unique needs of each occasion.
          </p>
          <p className="mt-6 text-xl leading-8 text-white ">
            Our holistic approach to event medical management encompasses
            pre-planning, on-site medical support, and post-event evaluations.
          </p>
          <div className="mt-10 flex items-center justify-start gap-x-6">
            <Link
              smooth
              to="#event-list"
              className="rounded-md bg-sky-600 px-3.5 py-2.5 text-md font-semibold text-white shadow-sm hover:bg-teal-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Events
            </Link>
            <Link
              to="/contacts"
              className="text-md font-semibold leading-6 text-white hover:text-teal-600"
            >
              Contact us <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

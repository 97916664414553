import CTA from "../../components/CTA";
import Clients from "../../components/Clients";
import SeparatorRight from "../../components/SeparatorRight";
import SeparatorLeft from "../../components/SeparatorLeft";
import WorkWithUs from "../../components/WorkWithUs";
import EventHero from "./EventHero";
import Staff from "../../components/Staff";

import EventStatement from "./EventStatement";
import EventTypes from "./EventTypes";
import Compliance from "../../components/Compliance";
import { Helmet } from "react-helmet";

export default function Events() {
  return (
    <div>
      <Helmet>
        <title>
          Event Medical Cover, On-Site Medical Support, Event First Aid
        </title>
        <link
          rel="canonical"
          href="https://medicalemergencysolutions.co.uk/services/event-medical-cover"
        />
        <meta
          name="description"
          content="Whether it's a music festival, a sports event, or a corporate gathering, MES provides tailored medical solutions to meet the unique needs of each occasion."
        />
      </Helmet>
      <EventHero />
      <SeparatorRight />
      <EventStatement />
      <SeparatorLeft />
      <EventTypes />

      <CTA />
      <Compliance />
      <Staff />
      <Clients />
      <WorkWithUs />
    </div>
  );
}

import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const features = [
  {
    name: "Emergency Medicine Trained Consultants.",
    description:
      "Experts in emergency medicine, possessing extensive training and experience in handling a wide range of acute medical conditions and emergencies.",
    icon: ChevronRightIcon,
  },
  {
    name: "No appointment needed.",
    description:
      "Receive prompt evaluation and care without the need for an appointment.",
    icon: ChevronRightIcon,
  },
  {
    name: "Long working hours.",
    description:
      "The facilities are open from 8am to 7pm, every day of the year.",
    icon: ChevronRightIcon,
  },
];

export default function UrgentCare() {
  return (
    <div className="overflow-hidden bg-white py-20 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          {/* <img
            alt="Doctor at a desk writing notes during appointment"
            src="/images/ucc-portrait.jpg"
            width={2432}
            height={1442}
            className="w-full  max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover object-center self-center"
          /> */}
          <picture>
            <source
              srcSet="/images/mes-ucc-480.png"
              media="(max-width: 768px)"
            />
            <source
              srcSet="/images/mes-ucc-768.png"
              media="(max-width: 1200px)"
            />

            <source srcSet="/images/mes-ucc-1000.png" />

            <img
              alt="MES doctor and patient during appointment at the Urgent Care Center Cromwell Hospital"
              src="/images/mes-ucc-1000.png"
              className="w-full  max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 object-cover object-center self-center"
              loading="lazy"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 75vw, 50vw"
            />
          </picture>
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <span className="text-base font-semibold leading-7 text-sky-700">
                We are pioneers
              </span>
              <Link to="/urgent-care-centre">
                <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  UCC - Urgent Care Centre
                </h2>
              </Link>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                In partnership with Cromwell Hospital, we have established a{" "}
                <a
                  href="https://www.cqc.org.uk/location/1-10598537375"
                  target="blank"
                  rel="noopener noreferrer"
                  className="font-bold text-sky-700 hover:text-teal-600"
                >
                  consultant-led private Urgent Care Centre
                </a>
                , offering patients rapid access to treatment for injuries and
                illnesses.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        aria-hidden="true"
                        className="absolute left-1 top-1 h-5 w-5 text-sky-700"
                      />
                      {feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10 flex items-start gap-x-6 ml-10">
                <Link
                  to="/urgent-care-centre"
                  className="text-md font-semibold leading-6 text-sky-800 hover:text-teal-600"
                >
                  Find out more <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

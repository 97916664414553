import { Link } from "react-router-dom";

export default function People() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none lg:grid lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our people
            </h2>
            <p className="mt-4 text-lg leading-8 text-gray-600">
              Our aim is to deliver excellent patient experiences and to be a
              <strong> family-friendly business</strong> with a{" "}
              <strong> welcoming, supportive, and caring atmosphere</strong>.
              This allows us to recruit, retain, and develop high-quality staff
              who are dedicated to providing compassionate care to patients in
              both the NHS and private sectors.
            </p>

            <p className="mt-4 text-lg leading-8 text-gray-600">
              We prioritise the quality of care by ensuring every member of our
              staff is thoroughly{" "}
              <strong>trained and continually supported</strong>. This
              commitment to professional development maximises their skills and
              education, essential for delivering the highest standards of
              patient care.
            </p>
            <div className="mt-8">
              <Link
                to="/careers"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-5 py-3 text-base font-medium text-white hover:bg-teal-600"
              >
                Join our team →
              </Link>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 lg:col-start-2 lg:row-span-2">
            <picture>
              <source
                srcSet="/images/mes-people-airport-768.jpg"
                media="(max-width: 490px)"
              />
              <source
                srcSet="/images/mes-people-airport-900.jpg"
                media="(max-width: 768px)"
              />

              <source srcSet="/images/mes-people-airport-1200.jpg" />

              <img
                src="/images/mes-people-airport-1200.jpg"
                alt="MES staff posing after a successful high risk ambulance transfer"
                className="w-full h-full object-cover object-center rounded-lg shadow-xl ring-1 ring-black ring-opacity-5"
              />
            </picture>
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-6 sm:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 lg:grid-rows-2 lg:gap-8">
          <div className="row-span-2">
            <picture>
              <source
                srcSet="/images/mes-people-ambulance-768.jpg"
                media="(max-width: 490px)"
              />
              <source srcSet="/images/mes-people-ambulance-900.jpg" />

              <img
                src="/images/mes-people-ambulance-900.jpg"
                alt="Ambulance staff performing checks inside ambulance"
                className="w-full  h-full lg:h-4/5 rounded-lg object-cover object-center shadow-xl ring-1 ring-black ring-opacity-5"
              />
            </picture>
          </div>
          <div className="col-span-1 row-span-2">
            <picture>
              <source
                srcSet="/images/mes-people-fast-response-768-2.jpg"
                media="(max-width: 490px)"
              />
              <source srcSet="/images/mes-people-fast-response-900 2.jpg" />

              <img
                src="/images/mes-people-fast-response-900-2.jpg"
                alt="MES paramedic entering a fast-response car"
                className="hidden md:block w-full  h-full lg:h-2/3 rounded-lg object-cover object-left shadow-xl ring-1 ring-black ring-opacity-5"
              />
            </picture>
          </div>
          <div className="col-span-1 row-span-1">
            <picture>
              <source
                srcSet="/images/mes-people-base-768.jpg"
                media="(max-width: 490px)"
              />
              <source srcSet="/images/mes-people-base-900.jpg" />

              <img
                src="/images/mes-people-base-900.jpg"
                alt="base staff inside ambulance' driving cabin"
                className="hidden md:block w-70 h-auto rounded-lg object-cover shadow-xl ring-1 ring-black ring-opacity-5 "
              />
            </picture>
          </div>
        </div>
      </div>
    </div>
  );
}

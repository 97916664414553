import { Link } from "react-router-dom";

export default function ServicesTiles() {
  const posts = [
    {
      id: 1,
      title: "Urgent Care Centre",
      href: "/urgent-care-centre",
      description:
        "We offer walk-in services for injuries and illnesses in West London",
      image: "/images/mes-services-ucc-480.png",
      imageAlt: "Doctor at a desk writing notes",
    },
    {
      id: 2,
      title: "Pre-Hospital Care",
      href: "/services/pre-hospital-care",
      description:
        "Swift and effective on-site medical response when every second counts",
      image: "/images/mes-services-prehospital.jpg",
      imageAlt: "Paramedic checking equipment and supplies",
    },
    {
      id: 3,
      title: "Event Medical Cover",
      href: "/services/event-medical-cover",
      description:
        "Ensure peace of mind with our expert event first aid services",
      image: "/images/mes-services-events.jpg",
      imageAlt: "Runner crossing the final line at a marathon",
    },
    {
      id: 4,
      title: "Private Ambulance Transfer",
      href: "/services/private-ambulance-transfer",
      description:
        "We provide dedicated private ambulance transport services across England",
      image: "/images/mes-services-ambulance-transport.png",
      imageAlt: "Ambulance staff transporting a patient inside the ambulance",
    },
    {
      id: 5,
      title: "Who we are",
      href: "/about",
      description: "Meet the force driving our extraordinary team",
      image: "/images/mes-services-whoweare.png",
    },
    {
      id: 6,
      title: "Work with us",
      href: "/careers",
      description: "Discover exciting career opportunities with us.",
      image: "/images/mes-services-joinus.png",
      imageAlt: "Two ambulance staff posing behind the ambulance",
    },
  ];
  return (
    <>
      <div
        id="services-section"
        className="bg-gradient-to-b from-stone-900 to-sky-800 py-24 sm:py-32 -z-10"
      >
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Learn about MES
            </h2>
            <p className="mt-2 text-lg leading-8 text-gray-300">
              Get to know our team and discover how we can assist you.
            </p>
          </div>
          <div className="mx-auto mt-10 border-t border-gray-200 pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none">
            <div className="flex space-x-6 overflow-x-auto pb-10 overflow-x-scroll scrollbar-visible">
              {posts.map((post) => (
                <article
                  key={post.id}
                  className="flex-shrink-0 max-w-60 md:w-80 flex flex-col items-start justify-between bg-cover bg-center bg-no-repeat p-6 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
                  style={{ backgroundImage: `url(${post.image})` }}
                >
                  <div className="bg-white bg-opacity-75 p-4 rounded-lg">
                    <div className="group relative">
                      <Link to={post.href}>
                        <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 h-10">
                          <span className="absolute inset-0" />
                          {post.title}
                        </h3>
                      </Link>
                      <p className="mt-5 text-sm leading-6 text-gray-600">
                        {post.description}
                      </p>
                    </div>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4"></div>
                </article>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
